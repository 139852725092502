.text-input-login {
  background-color: #ebebeb;

  outline: none;
  color: #2e2e2e;
}

.text-input-login::first-line,
.text-input-login:-webkit-autofill,
.text-input-login:-webkit-autofill:hover,
.text-input-login:-webkit-autofill:focus,
.text-input-login:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #e9e9e9 inset !important;
  -webkit-box-shadow: 0 0 0 30px #e9e9e9 inset !important;
}

.text-input-group label { 
  position: absolute;
  left: 16px;
  top: 10px;
  color: #888888;
  font-weight: 500;
}

input.force-label-up ~ label, /* Usado porque Chrome no actualiza input con autofill */
input.login-input:focus ~ label,
input.login-input:valid ~ label {
  top: -20px;
  left: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #838383;
}

.transition-font-size {
  transition-property: font-size;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}
