

.radial-background {
    background: rgb(10,10,10);
    background: radial-gradient(circle at 65% 30%, rgb(31, 31, 31) 0%, rgba(0,0,0,1) 40%);
  }
  

.skew {
    transform: skew(-24deg) translateX(4rem);
}

.skew-24 {
    transform: skew(-24deg)
}


.pulse-star {
    animation: spark-animation 2s ease-in-out infinite;
}

.pulse-star:nth-child(1) {
    animation-delay: 200ms;
    animation-duration: 1s;
}

.pulse-star:nth-child(2) {
    animation-delay: 350ms;
    animation-duration: 2s;
}



@keyframes spark-animation {
    0%, 100% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.7);
    }
}