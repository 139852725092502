/* Usado en FilterAnalyticsModal.js */
.radio-item {
  border: 2px solid black;
}

.radio-indicator::after {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 9999px;
  background-color: black;
}

.radio-background {
  transition-property: background;
  transition-duration: 100ms;
}

.verification-code-input{
  font-size: 18px; 
}

.radio-item[data-state="checked"] ~ .radio-background {
  background-color: rgba(68, 64, 60, 0.1);
}

/* Usado en MultipleSelectedToast.js */

.toast-animation[data-state="open"] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.toast-animation[data-state="closed"] {
  animation: swipeOut 150ms ease-in;
}
.toast-animation[data-swipe="end"] {
  animation: swipeOut 100ms ease-out;
}


/* Usado en MultipleCardEditionModal.js */

.modal-animation {
  transform-origin: center;
  animation: expand 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.overlay-animation {
  animation: overlayShow 150ms ease-in;
}

span{
  display: block;
  color: tomato;
  font-size: small;
}

@keyframes expand {
  from {
    scale: 0.95;
  }
  to {
    scale: 1;
  }
}


@keyframes slideIn {
  from {
    transform: translateX(10rem);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(10rem);
    opacity: 0;
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

