.radial-background {
  background: rgb(10, 10, 10);
  background: radial-gradient(
    circle at 15% 25%,
    rgb(31, 31, 31) 0%,
    rgba(0, 0, 0, 1) 40%
  );
}

.neon-light-animation {
  opacity: 0.4;
  animation: neon-animation 10s ease-in-out infinite;
  filter: drop-shadow(2px 1px 2px rgb(215 215 215));
}

@keyframes neon-animation {
  0%,
  100%,
  40%,
  60% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.8;
  }
}

.font-clamp-h1 {
  font-size: clamp(2rem, 0.5357rem + 2.6786vw, 3.75rem);
}

.font-clamp-description {
  font-size: clamp(1.2rem, 0.9643rem + 0.4464vw, 1.5rem);
}

.card-animation {
  transition-property: left, bottom;
  transition-duration: 2s;
  left: -1rem;
  bottom: 10%;
  transform: rotate(0deg);
  animation: wiggle-animation 3.5s cubic-bezier(0.445, 0.05, 0.55, 0.95)
      infinite,
    neon-animation 10s ease-in-out infinite;
  animation-delay: 7s;
}

.comment-animation {
  transition-property: left, bottom;
  transition-duration: 2s;
  right: -3rem;
  bottom: 4rem;
  animation: wiggle-animation 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite,
    neon-animation 10s ease-in-out infinite;
  animation-delay: 2s;
}

/* Me equivoque y no tengo tiempo de corregirlo
   TODO: Limpiar este css
*/
.wiggle-class-animation { 
  animation: wiggle-animation 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite,
  neon-animation 10s ease-in-out infinite;
}

.signal-animation {
  transition-property: right, top;
  transition-duration: 2s;
  left: 50%;
  top: 20%;
  animation: wiggle-animation 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite,
    neon-animation 10s ease-in-out 300ms infinite;
}

@keyframes wiggle-animation {
  0%,
  100% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(10deg);
  }
}

.left,
.right {
  position: absolute;
  height: 5rem;
  width: 3rem;
  bottom: 0;
}

.left {
  left: -3rem;
  background-color: rgb(250 250 249);
}

.left div {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0);
  border-bottom-right-radius: 3rem;
}

.right {
  right: -3rem;
  background-color: rgb(250 250 249);
}

.right div {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0);
  border-bottom-left-radius: 3rem;
}

.rounded-benefits-container {
  border-radius: 2rem;
}

.rounded-benefits-card {
  border-radius: 2rem;
}

.hover-scale-97:hover {
  transform: scale(0.97);
}

/* BARRA DE NAVEGACION | NAVBAR.JS */

.strikethrough-hover {
  width: fit-content;
  position: relative;
}

.strikethrough-hover::after {
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3px;
  content: "";
  background: #e4e4e4;
  transition: transform;
  transition-duration: 150ms;
  transform-origin: left;
  transform: scaleX(0) translateY(50%);
}

.strikethrough-hover:hover::after,
.strikethrough-hover:active::after {
  transform: scaleX(1);
}

/* REVIEWS LIST | REVIEWSLIST.JS */

:root {
  --review-duration: 0.4s;
}

.slide-left-out {
  animation: slide-left var(--review-duration), fade-out var(--review-duration);
}

.slide-right-out {
  animation: slide-right-out var(--review-duration), fade-out var(--review-duration);
}

.slide-left-in {
  animation: slide-left-in var(--review-duration), fade-in var(--review-duration);
}

.slide-right-in {
  animation: slide-right var(--review-duration), fade-in var(--review-duration);
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-3rem);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(3rem);
  }

  100% {
    transform: translateX(0rem);
  }
}

@keyframes slide-right-out {
  0% {
    transform: translateX(0rem);
  }

  100% {
    transform: translate(3rem);
  }
}

@keyframes slide-left-in {
  0% {
    transform: translateX(-3rem);
  }

  100% {
    transform: translate(0rem);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
