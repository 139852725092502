


.shadow-background {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 2px 15px, rgba(0, 0, 0, 0.23) 0px 4px 4px; 
}

.shadow-avatar {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 1px 4px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
}


.font-preview {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  