.radial-background {
  background: rgb(10,10,10);
  background: radial-gradient(circle at 15% 25%, rgb(31, 31, 31) 0%, rgba(0,0,0,1) 40%);
}

:root {
  --confetti-y: 30px;
  --confetti-x: -20px;
  --confetti-duration: 0.5s;
}

.confetti-animation {
  animation: gravity-animation var(--confetti-duration) cubic-bezier(.47,.16,.51,.88),
  horizontal-animation var(--confetti-duration) linear,
  opacity-animation var(--confetti-duration) linear;
}

@keyframes opacity-animation {
  0%, 40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}

@keyframes gravity-animation {
  0% {
    top: 0;
  }
  30% {
    top: var(--confetti-y);
  }
  100% {
    top: calc( -5 * var(--confetti-y) );
  }
}

@keyframes horizontal-animation {
  0% {
    left: 0;
  }
  100% {
    left: var(--confetti-x);
  }
}



/* LoginModal.js */


.login-content-show {
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

